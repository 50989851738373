<template>
  <VehicleIndex
    :fst-id="`marketplace-vehicles`"
    :page-title="
      $t(
        'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.headline'
      )
    "
    :isAddAnother="false"
    :isShowOnlyPageTitle="true"
    :endpoint="getEndpoint"
    :qso="getEndpointOptions"
    :filterItems="filterOptions"
    :tableHeaders="tableHeaders"
    @edit="edit"
  />
</template>

<script>
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils/EventBus'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import { VehicleIndex } from '@/composites/vehicle'
export default {
  name: 'ViewMarketplaceVehicles',
  components: {
    VehicleIndex,
  },

  data() {
    return {
      is_edit: false,
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.name'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.battery'
          ),
          width: '10%',
          sort: 'lock__power_level',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.IMEI'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.qRCode'
          ),
          width: '10%',
          sort: null,
        },
        // { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.lastConnected'), width: '12%', sort: null },
        // { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.lastLocated'), width: '10%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.flags'
          ),
          width: '12%',
          sort: null,
        },
        // { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.heartbeat'), width: '8%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.heartbeat'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.lastLocation'
          ),
          width: '9%',
          sort: null,
        },

        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.table.columns.assignTo'
          ),
          width: '13%',
          sort: null,
        },
      ],
      filterOptions: [
        {
          key: 'bike_category',
          type: 'checkbox',
          input: [
            { text: 'Scooters', value: 'P' },
            { text: 'Scooter Pro', value: 'PP' },
            { text: 'EBikes', value: 'E' },
            { text: 'Bikes', value: 'S' },
            { text: 'SuperCoco', value: 'C' },
            { text: 'Mopeds', value: 'K' },
          ],
          title: 'Category',
        },
        {
          key: 'status_flag',
          type: 'checkbox',
          input: [
            { text: 'On Rent', value: 'on_ride' },
            { text: 'On Reservation', value: 'on_reservation' },
            { text: 'On Parking', value: 'on_parking' },
            { text: 'On Illegaly Parked', value: 'illegally_parking' },
            { text: 'On Charging', value: 'on_charging' },
            { text: 'On Idle', value: 'idle' },
            { text: 'Damaged', value: 'damaged' },
          ],
          title: 'Status Flags',
        },

        {
          key: 'general_flag',
          type: 'checkbox',
          input: [
            { text: 'Missing', value: 'missing' },
            { text: 'IoT Fault', value: 'iot_fault' },
            { text: 'GeoFence Alert', value: 'geofence_alert' },
          ],
          title: 'General Flags',
        },

        {
          key: 'ops_flag',
          type: 'checkbox',
          input: [
            { text: 'Rebalance', value: 'rebalance' },
            { text: 'Charging Pick', value: 'charging_pick' },
            { text: 'Maintenance', value: 'maintenance' },
            { text: 'Swap Battery', value: 'swap_battery' },
          ],
          title: 'Operational Flags',
        },

        {
          key: 'active',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'true' },
            { text: 'Inactive', value: 'false' },
          ],
          title: 'Connectivity',
        },

        {
          key: 'locked',
          type: 'checkbox',
          input: [
            { text: 'Locked', value: 'true' },
            { text: 'Unlocked', value: 'false' },
          ],
          title: 'Lock',
        },
        {
          key: 'fetch_test_vehicles',
          type: 'checkbox',
          input: [{ text: 'Yes', value: true }],
          title: 'Include Test Vehicle',
        },

        {
          key: 'power',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 100,
            minQs: 'min_power_level', // query string for min value
            maxQs: 'max_power_level', // query string for max value
          },
          title: 'Battery',
        },
      ],
    }
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    getEndpoint() {
      return useEndpoints.vehicle.index() + `?marketplacefleet=${this.id}&`
    },

    getEndpointOptions() {
      return {
        prepend: '',
        append: '',
      }
    },
  },

  methods: {
    edit({ vehicle }) {
      console.log(vehicle)
      this.is_edit = true
      EventBus.$emit(SingleScooterConfig.events.editingData, vehicle)
      dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    },
  },
}
</script>
